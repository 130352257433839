<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Service Request
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="serviceRequestEditForm"
    >
      <b-form @submit.prevent="submitForm">
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Service Requests Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type*"
                  label-for="type"
                  label-cols-md="3"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="workFlow"
                    label="name"
                    placeholder="Select an option"
                    disabled
                    :options="workFlowOptions"
                    :reduce="name => name._id"
                    :selectable="option => !option.hasOwnProperty('category')"
                    :clearable="false"
                  >
                    <template #option="{ category, name, }">
                      <div
                        v-if="category"
                        class="group"
                      >
                        {{ category }}
                      </div>
                      {{ name }}
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="workFlowValidation"
                    class="text-danger"
                  >
                    {{ workFlowError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Date"
                vid="date"
                rules="required"
              >
                <b-form-group
                  label="Date*"
                  label-cols-md="3"
                  label-for="date"
                  :class="{ 'is-invalid': errors.length }"
                >
                  <b-input-group>
                    <flat-pickr
                      id="date"
                      v-model="date"
                      class="form-control flat-pickr-group"
                      placeholder="Select a Date"
                      :config="flatPickrConfigWithoutTime"
                      :disabled="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest')"
                      @input="checkForAssigneeSchedule"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dateValidation"
                    class="text-danger"
                  >
                    {{ dateError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Time"
                vid="time"
                rules="required"
              >
                <b-form-group
                  label="Time*"
                  label-cols-md="3"
                  label-for="time"
                  :class="{ 'is-invalid': errors.length }"
                >
                  <b-input-group>
                    <flat-pickr
                      id="time"
                      v-model="time"
                      class="form-control flat-pickr-group"
                      placeholder="Select a Time"
                      :config="flatPickrConfigTime"
                      :disabled="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="timeValidation"
                    class="text-danger"
                  >
                    {{ timeError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col md="6">
              <b-form-group
                label="Location*"
                label-cols-md="3"
                label-for="location"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  vid="location"
                  rules="required"
                >
                  <b-form-input
                    id="location"
                    v-model="location"
                    placeholder="e.g. Block A"
                    :state="(errors.length > 0) ? false : null"
                    name="location"
                    :readonly="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationValidation"
                    class="text-danger"
                  >
                    {{ locationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
            <b-col md="6">
              <b-form-group
                label="Related File No."
                label-cols-md="3"
                label-for="fileNo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Related File No."
                  vid="fileNo"
                  rules=""
                >
                  <b-form-input
                    id="fileNo"
                    v-model="fileNo"
                    placeholder="Related File No."
                    :state="(errors.length > 0) ? false : null"
                    name="fileNo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
            <b-col md="6">
              <b-form-group
                label="Remarks"
                label-cols-md="3"
                label-for="remarks"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-input
                    id="remarks"
                    v-model="remarks"
                    placeholder="Remarks"
                    :state="(errors.length > 0) ? false : null"
                    name="remarks"
                    :readonly="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="serviceForm"
                rules="required"
              >
                <b-form-group
                  label="Related Service Form*"
                  label-for="serviceForm"
                  label-cols-md="3"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="serviceForm"
                    v-model="serviceForm"
                    label="stringID"
                    placeholder="e.g. SF000048"
                    :options="serviceFormOptions"
                    :reduce="stringID => stringID._id"
                    :clearable="false"
                    :disabled="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="serviceFormValidation"
                    class="text-danger"
                  >
                    {{ serviceFormError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <div ref="service_description">
          <b-card
            v-show="customFields.length > 0"
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Service Descriptions
              </h3>
            </template>
            <b-row
              v-for="(opt, key) in customFields"
              :key="key"
            >
              <b-col cols="12">
                <div v-if="opt.type == 'title-and-text'">
                  <p class="text-bold-black">
                    {{ opt.label }}
                  </p>
                  <p>
                    {{ serviceDescription[key].text }}
                  </p>
                </div>
                <b-form-group
                  v-if="opt.type == 'short-answer'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-input
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      :placeholder="opt.label"
                      :state="(errors.length > 0) ? false : null"
                      :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'long-answer'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-textarea
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      trim
                      :placeholder="opt.label"
                      :state="(errors.length > 0) ? false : null"
                      :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'increment'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-spinbutton
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      inline
                      class="spin-button-sd"
                      :state="(errors.length > 0) ? false : null"
                      :step="opt.extraOptions[2].value"
                      :min="opt.extraOptions[0].value"
                      :max="opt.extraOptions[1].value"
                      :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                  label-cols-md="2"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  :class="{ 'pointer-none': !canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm') }"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >

                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox"
                    >
                      <b-form-checkbox
                        v-for="(optn, kindex) in opt.extraOptions"
                        :key="kindex"
                        :value="optn.key"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="serviceDescription[key].value.includes(optn.key)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ optn.key }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                  :class="{ 'pointer-none': !canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm') }"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox"
                      @input="makeCheckboxToRadio(key)"
                    >
                      <b-form-checkbox
                        v-for="(optn, kindex) in opt.extraOptions"
                        :key="kindex"
                        :value="optn.key"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="serviceDescription[key].value.includes(optn.key)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ optn.key }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'single-select'"
                  label-cols-md="2"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >

                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox"
                      :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm')"
                      @input="makeCheckboxToRadio(key)"
                    >
                      <b-form-checkbox
                        v-for="(optn, kindex) in requiredOptions"
                        :key="kindex"
                        :value="optn.value"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="serviceDescription[key].value.includes(optn.value)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ optn.text }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <validation-provider
                  v-if="opt.type == 'dropdown'"
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-group
                    label-cols-md="2"
                    :label="opt.label"
                    :label-for="'event-custom-field-' + key"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      :id="'event-custom-field-' + key"
                      v-model="serviceDescription[key].value"
                      label="value"
                      placeholder="Select From List"
                      :options="opt.extraOptions"
                      :reduce="value => value.key"
                      :clearable="true"
                      :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-service-description', 'ServiceForm')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <hr v-if="(!opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.id) || (opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.parent)">
              </b-col>
            </b-row>
          </b-card>
        </div>

        <b-card
          v-if="canViewThisAction('update-payment-sr', 'ServiceRequest')"
          ref="payment_details"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Payment Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Payment Status"
                vid="paymentStatus"
                rules="required"
              >
                <b-form-group
                  label="Payment Status*"
                  label-for="h-bookings-paymentStatus"
                  label-cols-md="4"
                  :state="(errors.length > 0 || paymentStatusValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-paymentStatus"
                    v-model="paymentStatus"
                    label="title"
                    placeholder="Select from list"
                    :options="paymentStatusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="paymentStatusValidation == true ? paymentStatusValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentStatusValidation"
                    class="text-danger"
                  >
                    {{ paymentStatusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <b-form-group
                label="Payment Remarks"
                label-for="h-booking-paymentRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Remarks"
                  vid="paymentRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-paymentRemarks"
                    v-model="paymentRemarks"
                    placeholder="Payment Remarks"
                    rows="3"
                    name="paymentRemarks"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-payment', 'ServiceForm')"
                    @input="paymentRemarksValidation == true ? paymentRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentRemarksValidation"
                    class="text-danger"
                  >
                    {{ paymentRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Others
            </h3>
          </template>
          <b-row>
            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Assignees"
                vid="assignees"
                rules=""
              >
                <b-form-group
                  label="Assignee"
                  label-for="h-user-roles"
                  label-cols-md="2"
                  :state="(errors.length > 0 || assigneesValidation) ? false : null"
                >
                  <!-- <v-select
                    id="h-user-roles"
                    v-model="roles"
                    label="role"
                    multiple
                    :options="roleOptions"
                    :reduce="role => role._id"
                    @input="roleValidation == true ? roleValidation = false : null"
                  /> -->

                  <b-form-tags
                    v-model="assignees"
                    size="lg"
                    add-on-change
                    no-outer-focus
                    :disabled="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-assignee', 'ServiceRequest')"
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveUserName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose a User....
                          </option>
                        </template>
                        <option
                          v-for="(user, ind) in userOptions"
                          :key="ind"
                          :value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="assigneesValidation"
                    class="text-danger"
                  >
                    {{ assigneesError }}
                  </small>
                </b-form-group>
              </validation-provider>

              <div
                v-if="assignmentError"
                class="service_assign_error"
              >
                <h5>Warning</h5>
                <div>
                  <p>Assingee(s) may not be available for the the request. Are you sure you want to proceed?</p>
                  <ul>
                    <li
                      v-for="(error, index) in assignmentErrorOptions"
                      :key="index"
                    >
                      {{ error.date }} - {{ error.name }} ({{ error.leave }})
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-user-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-user-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    :disabled="!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-status', 'ServiceRequest')"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-service-requests-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BFormTags, BFormTag, BFormSelect, BFormTextarea,
  BInputGroup, BInputGroupAppend, BFormCheckboxGroup, BFormCheckbox, BFormSpinbutton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, min } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormTags,
    BFormTag,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSpinbutton,
    BFormTextarea,

    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      savedData: false,
      workFlow: '',
      serviceForm: this.$route.query.form || '',
      date: '',
      time: '',
      location: '',
      fileNo: '',
      remarks: '',
      status: 'pending',
      assignees: [],
      workFlowError: 'Valid workFlow is required',
      workFlowValidation: false,
      serviceFormError: 'Valid serviceForm is required',
      serviceFormValidation: false,
      dateError: 'Valid date is required',
      dateValidation: false,
      timeError: 'Valid time is required',
      timeValidation: false,
      locationError: 'Valid location is required',
      locationValidation: false,
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      assigneesError: 'Valid assignees is required',
      assigneesValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Pending', code: 'pending' }, { title: 'Completed', code: 'completed' }],
      workFlowOptions: [],
      serviceFormOptions: [],
      userOptions: [],
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      assignmentError: false,
      alreadyCheckedSchedule: false,
      assignmentErrorOptions: [],
      serviceDescription: [],
      customFields: [],
      previousValues: [],
      venueField: '',
      titleFields: '',
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      paymentStatus: 'N/A',
      paymentStatusError: '',
      paymentStatusValidation: false,
      paymentRemarks: '',
      paymentRemarksError: 'Valid value is required',
      paymentRemarksValidation: false,
      paymentStatusOptions: [
        { title: 'Paid', code: 'Paid' },
        { title: 'Partially Paid', code: 'Partially Paid' },
        { title: 'Unpaid', code: 'Unpaid' },
        { title: 'FSP', code: 'FSP' },
        { title: 'N/A', code: 'N/A' },
      ],

      // validation rules
      required,
      min,
    }
  },
  watch: {
    assignees() {
      this.checkForAssigneeSchedule()
    },
  },
  async beforeMount() {
    await this.$http.get(`operation/service-requests/${this.$route.params.id}`)
      .then(response => {
        this.serviceForm = response.data.serviceForm || ''
        this.workFlow = response.data.workFlow || ''
        this.date = `${this.dateFormat(response.data.date)}` || ''
        this.time = `${this.twentyFourHourTimeFormatFromDateString(response.data.date)}` || ''
        this.location = response.data.location || ''
        this.remarks = response.data.remarks || ''
        this.fileNo = response.data.fileNo || ''
        this.status = response.data.status || 'pending'
        this.paymentRemarks = response.data.paymentRemarks || ''
        this.paymentStatus = response.data.paymentStatus || 'N/A'
        this.assignees = response.data.assignees || []
        this.previousValues = response.data.serviceDescription || []

        document.title = `Editing ${response.data.stringID} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get('operation/service-requests/respond-with/extra-options', { params: { department: true } })
      .then(async response => {
        const workFlowCats = response.data.workFlowCats ?? []
        this.serviceFormOptions = response.data.serviceFormOptions ?? []
        this.userOptions = response.data.userOptions ?? []
        this.venueField = response.data.venueField ?? ''
        this.titleFields = response.data.titleFields ?? ''

        workFlowCats.forEach(element => {
          this.workFlowOptions.push({
            category: element.name,
            name: null,

            _id: element._id,
          })
          element.workFlows.forEach(flow => {
            this.workFlowOptions.push({
              name: flow.name,

              _id: flow._id,
            })
          })
        })

        const { serviceDescription } = response.data
        if (serviceDescription) {
          if (serviceDescription.customFields.length) {
            const newArray = []
            serviceDescription.customFields.forEach(arrayItem => {
              const newObj = {}
              newObj.key = arrayItem.label
              newObj.type = arrayItem.type
              newObj.id = arrayItem.id
              newObj.parent = arrayItem.parent
              const exist = this.previousValues.find(o => o.id === arrayItem.id)
              const old = this.previousValues.find(o => o.key === arrayItem.label)
              if (exist) {
                if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                  if (Array.isArray(exist.value)) {
                    newObj.value = exist.value || []
                  } else {
                    newObj.value = [exist.value] || []
                  }
                } else if (arrayItem.type === 'title-and-text') {
                  newObj.value = ''
                  newObj.text = arrayItem.description
                } else if (arrayItem.type === 'increment') {
                  newObj.value = +exist.value
                } else {
                  newObj.value = exist.value || ''
                }
              } else if (old) {
                if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                  if (Array.isArray(old.value)) {
                    newObj.value = old.value || []
                  } else {
                    newObj.value = [old.value] || []
                  }
                } else if (arrayItem.type === 'title-and-text') {
                  newObj.value = ''
                  newObj.text = arrayItem.description
                } else if (arrayItem.type === 'increment') {
                  newObj.value = +old.value
                } else {
                  newObj.value = old.value || ''
                }
              } else {
                // eslint-disable-next-line no-lonely-if
                if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                  newObj.value = []
                } else if (arrayItem.type === 'title-and-text') {
                  newObj.value = ''
                  newObj.text = arrayItem.description
                } else if (arrayItem.type === 'increment') {
                  newObj.value = +arrayItem.extraOptions[0].value
                } else {
                  newObj.value = ''
                }
              }
              newArray.push(newObj)
            })

            this.serviceDescription = newArray
          }

          this.customFields = serviceDescription.customFields || []
        }

        await this.$nextTick()
        const { section } = this.$route.query
        if (section) {
          const element = this.$refs[section]
          if (element) {
            const top = element.offsetTop - 135
            window.scrollTo({
              top,
              behavior: 'smooth',
            })
          }
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    checkForAssigneeSchedule() {
      // this.bulkAssigneeValidation === true ? this.bulkAssigneeValidation = false : null
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.assignees.length; i++) {
        formData.append('bulkAssignee[]', this.assignees[i])
      }

      formData.append('dueDate', this.date)
      if (this.assignees.length && this.date) {
        this.$http.post('operation/service-requests/task/check/assignee/schedule', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.data.assigneeWarning.length) {
              this.assignmentErrorOptions = response.data.assigneeWarning
              this.assignmentError = true
              // this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
            } else {
              this.assignmentErrorOptions = []
              this.assignmentError = false
              // this.alreadyCheckedSchedule = true
              // this.$refs['service-request-task-update-modal'].toggle('#toggle-btn')
              // this.submitTaskData()
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.assignmentErrorOptions = []
        this.assignmentError = false
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-service-requests-index', params: { type: 'all-service-requests' } })
          }
        })
    },
    resolveUserName(user) {
      const type = this.userOptions.find(o => o._id === user)
      if (type) {
        return type.name
      }
      return ''
    },
    makeCheckboxToRadio(key) {
      const lastSelected = this.serviceDescription[key].value.slice(-1)[0]
      if (lastSelected) {
        this.serviceDescription[key].value = this.serviceDescription[key].value.filter(code => code === lastSelected)
      }
    },
    submitForm() {
      this.$refs.serviceRequestEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('serviceForm', this.serviceForm)
          formData.append('workFlow', this.workFlow)
          formData.append('date', this.date)
          formData.append('time', this.time)
          formData.append('location', this.location)
          formData.append('remarks', this.remarks)
          formData.append('fileNo', this.fileNo)
          formData.append('status', this.status)
          formData.append('paymentRemarks', this.paymentRemarks)
          formData.append('paymentStatus', this.paymentStatus)
          formData.append('serviceDescription', JSON.stringify(this.serviceDescription))
          const serviceDescriptionValues = []
          let showServiceDescription = false
          let serviceDescriptionTitle = ''
          const titleArray = this.titleFields.split(',')
          this.serviceDescription.forEach(obj => {
            if (!obj.parent) {
              // sortedData.push(obj)
              const children = this.serviceDescription.filter(o => o.parent === obj.id)
              if (children.length) {
                const childrenArray = []
                children.forEach(opt => {
                  if (opt.type === 'title-and-text') {
                    const child = {
                      id: opt.id,
                      parent: opt.parent,
                      key: opt.key,
                      type: opt.type,
                      text: opt.text,
                      value: '',
                    }
                    childrenArray.push(child)
                  } else if (Array.isArray(opt.value)) {
                    if (opt.value.length) {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(opt.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.value.join(', ')}`
                        } else {
                          serviceDescriptionTitle += `+${opt.value.join(', ')}`
                        }
                      }
                    }
                  } else if (opt.value) {
                    if (opt.type === 'increment' && Number(opt.value) !== 0) {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: `x${opt.value}`,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} x${opt.value}`
                        } else {
                          serviceDescriptionTitle += ` ${opt.key} x${opt.value}`
                        }
                      }
                    } else {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} ${opt.value}`
                        } else {
                          serviceDescriptionTitle += ` ${opt.key} ${opt.value}`
                        }
                      }
                    }
                  }
                })
                const parent = {
                  id: obj.id,
                  parent: obj.parent,
                  key: obj.key,
                  type: obj.type,
                  text: obj.text,
                  value: obj.value,
                  children: childrenArray,
                }
                serviceDescriptionValues.push(parent)
              } else {
                // eslint-disable-next-line no-lonely-if
                if (obj.type === 'title-and-text') {
                  const parent = {
                    id: obj.id,
                    parent: obj.parent,
                    key: obj.key,
                    type: obj.type,
                    text: obj.text,
                    value: '',
                  }
                  serviceDescriptionValues.push(parent)
                } else if (Array.isArray(obj.value)) {
                  if (obj.value.length) {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.value.join(', ')}`
                      } else {
                        serviceDescriptionTitle += `+${obj.value.join(', ')}`
                      }
                    }
                  }
                } else if (obj.value) {
                  if (obj.type === 'increment' && Number(obj.value) !== 0) {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: `x${obj.value}`,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} x${obj.value}`
                      } else {
                        serviceDescriptionTitle += ` ${obj.key} x${obj.value}`
                      }
                    }
                  } else {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} ${obj.value}`
                      } else {
                        serviceDescriptionTitle += ` ${obj.key} ${obj.value}`
                      }
                    }
                  }
                }
              }
            }
          })
          formData.append('serviceDescriptionValues', JSON.stringify(serviceDescriptionValues))
          formData.append('showServiceDescription', showServiceDescription)
          formData.append('serviceDescriptionTitle', serviceDescriptionTitle)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.assignees.length; i++) {
            formData.append('assignees[]', this.assignees[i])
          }

          this.$http.patch(`operation/service-requests/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Service Request Updated',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Entry',
                  allowOutsideClick: false,
                  confirmButtonText: 'Return to Requests',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    this.savedData = true
                    if (result.value) {
                      this.$router.push({ name: 'operation-service-requests-index', params: { type: 'all-service-requests' } })
                    } else {
                      this.$router.push({ name: 'operation-service-requests-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'serviceForm') {
                    this.serviceFormError = validationError.msg
                    this.serviceFormValidation = true
                  } else if (validationError.param === 'workFlow') {
                    this.workFlowError = validationError.msg
                    this.workFlowValidation = true
                  } else if (validationError.param === 'date') {
                    this.dateError = validationError.msg
                    this.dateValidation = true
                  } else if (validationError.param === 'time') {
                    this.timeError = validationError.msg
                    this.timeValidation = true
                  } else if (validationError.param === 'location') {
                    this.locationError = validationError.msg
                    this.locationValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'assignees') {
                    this.assigneesError = validationError.msg
                    this.assigneesValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
